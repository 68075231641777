export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    theme: {
      type: String,
      validator: prop => ['light', 'dark'].includes(prop),
    },

    title: {
      type: String,
    },

    body: {
      type: String,
    },

    organic: {
      type: Boolean,
    },

    component: {
      type: Object,
      required: true,
    },

    swiperOptions: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasNavigation() {
      const hasNavigation = this.swiperOptions.hasOwnProperty('navigation')

      if (hasNavigation) return true
    },

    hasPagination() {
      const hasPagination = this.swiperOptions.hasOwnProperty('pagination')

      if (hasPagination) return true
    },

    hasOneSlidePerView() {
      const hasOneSlidePerView =
        this.swiperOptions.hasOwnProperty('slidesPerView') && this.swiperOptions.slidesPerView === 1

      if (hasOneSlidePerView) return true
    },

    options() {
      if (this.hasNavigation) {
        this.swiperOptions.navigation.prevEl = '.carousel__navigation-button--prev'
        this.swiperOptions.navigation.nextEl = '.carousel__navigation-button--next'
      }

      if (this.hasPagination) {
        this.swiperOptions.pagination.el = '.carousel__bullet-pagination'
      }

      return this.swiperOptions
    },

    themeClasses() {
      if (this.theme) return `carousel--${this.theme}`
    },

    bodyClasses() {
      if (this.body) return 'carousel--body'
    },

    leftArrow() {
      if (this.organic) return 'arrowLeft'

      return 'chevronLeft'
    },

    rightArrow() {
      if (this.organic) return 'arrowRight'

      return 'chevronRight'
    },
  },

  methods: {
    changeFractionPagination() {
      if (this.hasOneSlidePerView) {
        const totalPages = this.component.items.length
        const currentPage = this.$refs.swiper.swiper.activeIndex + 1
        const fractionPagination = this.$refs.fractionPagination

        fractionPagination.innerHTML = `${currentPage} — ${totalPages}`
      }
    },
  },

  mounted() {
    if (this.hasOneSlidePerView) this.changeFractionPagination()
  },
}
