import { render, staticRenderFns } from "./Carousel.vue?vue&type=template&id=951fc994&scoped=true"
import script from "./Carousel.js?vue&type=script&lang=js&external"
export * from "./Carousel.js?vue&type=script&lang=js&external"
import style0 from "./Carousel.scss?vue&type=style&index=0&id=951fc994&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "951fc994",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HotTitle: require('/runner/_work/app-hotmart/app-hotmart/components/Atoms/Title/Title.vue').default})
